<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100">
    <a-spin :spinning="spinnings" tip="資料載中..."> 
    <Menubar msg="test" @query="query" @getwinsize="getwinsize" v-if="type=='N'" ref="MyBar" />
      <div class="lineHeader" v-if="type=='Y'">
        <a-button type="link" @click="toNext()"  icon="left" class="fontstyle">
          回上一頁
        </a-button>        
      </div>  
      <ul>
        <li>
          <div class="mt-3" >
            <Orginforject v-if="uId!=='' && JSON.stringify(objs)!=='{}'" :obj="objs" :oid="uId" :winsize="windowsize" />
          </div>
        </li>
      </ul>
    </a-spin>   
    <div class="wrapper flex-grow-1"></div>      
    <Efootbar msg="test" /> 
    </div>
  </div>
</template>
<script>
import { makorgService } from '../_services'  
import { mapState } from "vuex";
import * as moment from "moment/moment";
import Orginforject from './panel/Orginforject.vue'
import Menubar from '@/components/Menubar.vue'
import Efootbar from '@/components/Efootbar.vue'
import router from '../router'

const queryData = params => {
  return makorgService.findorg(params.id) 
};


export default {
  name: 'Outorg',
  components: {
    Menubar,
    Efootbar,
    Orginforject,
  }, 
  props: {
    msg: String
  },
  data() {
    return {
      spinnings:true,
      type: 'N',
      classtheme:null,
      title:{title:'主辦單位',logo:"project"},
      windowsize: {
            width: 0,
            height: 0
        },        
      objs: {},      
      uId:'',
    };
  },  
  mounted(){
        //console.log(this.$route.query.userId);
    this.uId = this.$route.params.id
    this.type = this.$route.params.type
    this.fetch({id:this.uId});
    this.$refs["MyBar"].closebar(false);
    //this.objs=this.datas.find(item => item.id == this.defaultval.id)
  },
  computed: {
    ...mapState("account", ["status", "user"]), 
    ...mapState("makeorg", ["datas","defaultval"]),
  },  
  methods: {
    fetch(params = {}) {
      queryData({
        ...params,
      }).then(({ data }) => {
          this.objs = data;  
          this.spinnings = false        
      });
    },
    query(rs){
      this.classtheme = rs
    },  
    getwinsize(val){
      this.windowsize.width = val.width
      this.windowsize.height = val.height
    },       
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    toNext(){
      router.go(-1)
    },    
  },
  
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.lineHeader {
   position: fixed;
   left: 0;
   top: 0;
   z-index: 7;
   opacity:1;
   width: 100%;
   height: 2.3rem;
   padding: 0.2rem;
   background-color: #3b1b75;
   color: #f0f8f8;
   text-align: center;
   
}
.fontstyle{
   font-size:0.8rem;
   color:#faf8f8;
}
</style>

