<template>
  <div class="flex-container">
        <div class="class1" v-if="JSON.stringify(obj)!=='{}' && winsize.width>=991" >
          <div>
            <b-avatar class="avatar" :src="obj.prourl" size="11rem"></b-avatar>
          </div>          
          <div>
            <strong>主辦單位資料</strong>
            <b-list-group flush>
            <b-list-group-item class="text-left my-span">主辦單位名稱 : <span v-text="obj.name"></span>               
            </b-list-group-item>
            <b-list-group-item class="text-left my-span"> 上層 :  
              <span  v-if="mylevel!==null" v-text="mylevel.mydata.name=='admin' || mylevel.mydata.name==''?'':mylevel.mydata.name"></span> 
            </b-list-group-item>            
            <b-list-group-item class="text-left my-span">狀態 : <span v-text="cstate(obj.state)"></span></b-list-group-item>
            <br/>
            <button type="button"  @click="show=!show" class="btn btn-secondary btn-sm btn-block">主辦單位聯絡方式</button>
            </b-list-group>
            <div v-show="show">             
            <b-list-group>
              <b-list-group-item variant="info">聯絡資訊如下</b-list-group-item>
              <b-list-group-item variant="light" >
                主辦單位 Email :  <br/><span v-text="obj.email"></span><hr>                
                主辦單位手機號碼 : <br/><span v-text="obj.mphone"></span><hr>
                主辦單位聯絡地址 : <br/><span v-text="obj.address"></span>
              </b-list-group-item>
            </b-list-group>
            </div>                                    

          </div>
        </div>
        <div class="class2" v-if="JSON.stringify(obj)!=='{}'" >         
            <img :src="obj.titurl?  obj.titurl:catImg('myinfor.png')" class="img-fluid" alt="Responsive image">
            <div v-if="winsize.width<=991" class="Mbard">
                <b-avatar class="avatar" :src="obj.prourl" size="2.8rem"></b-avatar>
                &ensp; 主辦單位 : <span v-text="obj.name"></span> |&ensp; 
                狀態 : <span v-text="cstate(obj.state)"></span>        
            </div>
            <b-card no-body>
              <b-tabs card>
                <b-tab v-if="winsize.width<=991" title="聯絡方式" active>
                  <b-card-text>
                    <div style="height:450px;text-align:left;">
                      <b-list-group>
                        <b-list-group-item variant="info">聯絡資訊如下</b-list-group-item>
                        <b-list-group-item variant="light" >
                          主辦單位 Email :  <br/><span v-text="obj.email"></span><hr>                
                          主辦單位手機號碼 : <br/><span v-text="obj.mphone"></span><hr>
                          主辦單位聯絡地址 : <br/><span v-text="obj.address"></span>
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                  </b-card-text>
                </b-tab>                
                <b-tab title="單位簡介" active>
                  <b-card-text>
                    <div style="height:450px;text-align:left;">
                    <span v-text="obj.desc"></span>
                     <p></p>
                     <p><b-icon icon="flag-fill" scale="1.5" variant="secondary"></b-icon>&ensp;建立於 {{fdateformat(obj.created_at)}}</p>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="活動">
                    <div style="height:450px;text-align:left;background:#ECECEC;" id="style-3" class="overflow-auto">
                         <div class="post-preview" v-for="item in list" :key="item.id" style="padding: 20px;margin:4px 0px 4px 0px;">
                            <b-card no-body  class="overflow-hidden"
                            :style="{ 'margin': '10px', 'max-width' : '630px','border-radius':'20px', 'box-shadow':' 1px 5px 6px #f3f5f6' }"
                             >
                              <b-row no-gutters>
                                <b-col >
                                  <b-card-img :src="item.url!==''? item.url:mainImg('event_1080x540.png')" alt="Image" style="max-height:160px" class="rounded-0"></b-card-img>
                                </b-col>
                                <b-col >
                                  <b-card-body :title="'活動名稱 :'+item.actitle">
                                    <b-card-text>
                                      <router-link :to="{
                                      name:'Actinfo', 
                                      params: { id: item.id}
                                      }" target="_blank">
                                      詳細內容.....
                                    </router-link> 
                                     <p style="color:#c51212;">活動日期: {{  fdateformat(item.sdate)}}</p>
                                    </b-card-text>
                                  </b-card-body>
                                </b-col>
                              </b-row>
                            </b-card>
                        </div>
                        <div class="text-center">
                        <a-button
                          v-show="list.length>0"
                          style="margin-bottom: 20px; margin-top: 5px"
                          :loading="iconLoading"
                          @click="infiniteHandler()"
                          type="primary"
                          shape="round"
                          size="large"
                        >
                          <b-icon
                            v-show="!iconLoading"
                            style="margin-right: 0.3rem"
                            scale="1.1"
                            icon="cloud-download"
                            variant="light"
                          />
                          取得更多資料
                        </a-button> 
                      </div>                         
                    </div>
                </b-tab>
              </b-tabs>
            </b-card>                        

        </div>        
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import * as moment from "moment/moment";
import { makorgService } from '../../_services'
const queryData = params => {
  return makorgService.readorgbypage(params.page,params.size,params.orgid) 
};  


export default {
  name: "Orginforject",
  props: {
    obj: Object,
    oid: Number,
    winsize: Object,
  },
  data() {
    return {   
      iconLoading: false,        
      xurl: "",
      show:false,
      visible: false,
      page: 1,
      list: [],
      cid:null,      
      imgs: {
        width: 0,
        height: 0
      },
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["defaultval","mylevel"]),
  },
  methods: {
    ...mapActions('makeorg',['getorglevel']),
    groupBy(array, groups, valueKey) {
      var map = new Map();
      groups = [].concat(groups);
      return array.reduce((r, o) => {
        groups.reduce((m, k, i, { length }) => {
          var child;
          if (m.has(o[k])) return m.get(o[k]);
          if (i + 1 === length) {
            child = Object.assign(...groups.map(k => ({ [k]: o[k] })), {
              [valueKey]: 0
            });
            r.push(child);
          } else {
            child = new Map();
          }
          m.set(o[k], child);
          return child;
        }, map)[valueKey] += +o[valueKey];
        return r;
      }, []);
    },

    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },

    mainImg(imgs) {
      return require(`../../assets/${imgs}`);
    },      

    convertname(va1){
      let tmp=""
      va1=="org"? tmp="據點/團體組織":"";
      va1=="town"? tmp="局處/鄉鎮公所":"";
      va1=="county"? tmp="縣市政府":"";
      va1=="admin"? tmp="最高權限管理群組":"";
      return tmp
    }, 
    
    cstate(val){
      //return狀態 N草稿, I審核中, R發佈中, F結束, D下架
      let tmp=""
      val=="N"? tmp="尚未審核通過":"";
      val=="Y"? tmp="已審核":"";
      return tmp
    }, 
    
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD"); 
    }, 

	async infiniteHandler() {
    let params = {
            page: this.page,
            size: 2,
            orgid : this.oid        
        }
      await this.getRuner(params)
    },

    getRuner(params) {
      return new Promise((resolve) => {
      this.page++;
      queryData({
          ...params,
        }).then(( data ) => {
            let obj = data
            if (obj!=null) {
                //this.list.push(...obj);
                for (var prop in obj) {
                    if(this.list.find(rs=>rs.id==obj[prop].id)==undefined){
                      this.list.push(obj[prop]);
                    } 
                  }                                  
              resolve(true);
            } else {
              resolve(false);                              
            }
        });
      });
    },    

    done(id){
        this.cid = id
    }, 
    cancels(){
        this.cid=null
    }        


  },
  mounted () {
        /*this.themedata = this.mytheme 
        this.category=this.mycategory */
        this.infiniteHandler()
  },
  beforeDestroy() {},
  created(){
    if(this.defaultval.id!==''){
      this.getorglevel( {id:this.defaultval.id} ) 
    } 
  }  
};
</script>
<style scoped>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #535151;;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #535151;
}
.class1 {
  padding: 40px 40px 40px 40px;
  width:auto;
  height:auto;
  font-size: 14px;
  margin-top: 15px;
  margin-right: 10px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 12px; 
  background-color:#efeff3; 
}
.class1 div{
  margin-top:10px;
}
.avatar{
    box-shadow: 2px 2px 15px -5px rgb(46, 44, 44);    
    opacity: 0.7;
}
.class2 {
  padding: 0px 0px 0px 0px;
  width:auto;
  height:auto;
  margin-top: 15px;
  margin-right: 10px;
  max-width: 685px;
  max-height: 100%;
  border-radius: 12px; 
  background-color:#efeff3; 
}
.flex-container {
  display: inline-flex;
}
</style>
<style>
.class1 img{
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
} 
.class2 img{
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
} 

.card-title{
  font-size: 14px;
}
.card-text{
  font-size: 13px;
}
.nav-item{
    font-size: 13px;
}
.Mbard{
  background-color:#ffffff;
  color: #808383;
  border-top: 3mm ridge rgba(230, 233, 175, 0.6);
  font-size: 14px;
  text-align: left;
  padding:2px 2px 2px 20px;
  margin:2px;
}
.my-span {
  padding: 5px 10px; /* 調整內邊距 */
  border: 1px solid #bfdaf5; /* 設置邊線 */
  border-radius: 5px; /* 添加倒角 */
  background-color: linear-gradient(-45deg, #ffffff, #d2edf8, #ffffff);
}


</style>

